.rc-input-number {
  height: 22px !important;
  border-radius: 0 !important;
  display: block !important;
  &.rc-input-number-focused {
    border-color: $color-accent !important;
    box-shadow: none !important;
    .rc-input-number-handler-wrap, .rc-input-number-handler-up {
      @include themify($themes) {
        border-color: $color-accent !important;
      }
    }
    &:hover {
      border-color: $color-accent !important;
      .rc-input-number-handler-wrap, .rc-input-number-handler-up {
        @include themify($themes) {
          border-color: $color-accent !important;
        }
      }
    }
  }
  &:not(.rc-input-number-focused) {
    @include themify($themes) {
      border-color: themed('colorText') !important;
    }
    .rc-input-number-handler-wrap, .rc-input-number-handler-up {
      @include themify($themes) {
        border-color: themed('colorText') !important;
      }
    }
  }
}

.rc-input-number-input {
  border: none !important;
  text-align: left !important;
  &:focus, &:active {
    outline: none;
    border-color: $color-accent !important;
  }
}

.rc-input-number-handler {
  height: 50% !important;
  line-height: 6px !important;
}

.rc-input-number-handler-up-inner, .rc-input-number-handler-down-inner {
  @include themify($themes) {
    color: themed('colorText') !important;
  }
}
