.rdtPicker {
  @include themify($themes) {
    background: themed('colorBackground');
    color: themed('colorText');
    border: 1px solid themed('colorText');
  }
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  @include themify($themes) {
    background: themed('colorBorder');
  }
}

.rdtPicker th {
  @include themify($themes) {
    border-bottom: 1px solid themed('colorText');
  }
}

.rdtPicker thead tr:first-child th:hover {
  @include themify($themes) {
    background: themed('colorBorder');
  }
}

.rdtPicker tfoot {
  @include themify($themes) {
    border-top: 1px solid themed('colorText');
  }
}

.rdtPicker button:hover {
  @include themify($themes) {
    background-color: themed('colorBorder');
  }
}

td.rdtMonth:hover,
td.rdtYear:hover {
  @include themify($themes) {
    background: themed('colorBorder');
  }
}

.rdtCounter .rdtBtn:hover {
  @include themify($themes) {
    background: themed('colorBorder');
  }
}
