.crp-modal {
  .modal__body {
    tbody {
      display: block;
      max-height: 500px;
      overflow: scroll;
    }
    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }
  }
  .modal__footer {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    display: block !important;
    padding-left: 20px;
  }
  .checkbox-btn__checkbox-custom svg {
    fill: #fff;
    background: #70bbfd;
    border-color: #70bbfd;
  }
  .checkbox-btn__checkbox-custom {
    position: unset;
  }
  .crp-checkbox-instrument-td {
    padding-left: 25px;
  }
}

.update-btn-crp {
  float: right;
  margin-right: 5px !important;
  margin-top: 25px;
}

.stp-tr {
  line-height: 0;
}

.stp-tr-td-1 td {
  padding-bottom: 0 !important;
}

.stp-tr-td-2 td {
  padding-top: 0 !important;
}

.crp-select {
  width: 25%;
  label {
    width: 120px;
    @include themify($themes) {
      color: themed('colorText');
    }
    padding-left: 10px;
    padding-right: 20px;
  }
  .form__form-group-select {
    display: inline-block;
    width: calc(100% - 120px) !important;
  }
}

.lm_not .crp-select {
  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0;
    &:first-child {
      margin-right: 0;
    }
  }
}

.lm_crp-select .crp-select {
  width: 100%;
  margin-right: 0;

  &:first-child {
    margin-right: 0;
  }
}

.crp-row-hide {
  display: none;
}

.crp-re-captcha {
  padding-top: 15px;
  padding-left: 45px;
}

.crp-table {
  td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4),
  th:nth-child(1), th:nth-child(2), th:nth-child(3), th:nth-child(4) {
    width: 10%;
    min-width: 100px;
  }

  td:nth-child(5), td:nth-child(6), td:nth-child(7), td:nth-child(8), td:nth-child(9), td:nth-child(10), td:nth-child(11), td:nth-child(12), td:nth-child(13), td:nth-child(14),
  th:nth-child(5), th:nth-child(6), th:nth-child(7), th:nth-child(8), th:nth-child(9), th:nth-child(10), th:nth-child(11), th:nth-child(12), th:nth-child(13), th:nth-child(14) {
    width: 6%;
    min-width: 50px;
  }
  .checkbox-btn__checkbox-custom svg {
    fill: #fff;
    background: #70bbfd;
    border-color: #70bbfd;
  }
  .checkbox-btn__checkbox-custom {
    position: unset;
  }
  button {
    width: 100%;
    padding: 0;
  }
  .checkbox-btn {
    display: inline-flex;
  }
  .crp-checkbox-td {
    width: 100%;
    text-align: center;
  }
}
