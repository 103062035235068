.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  @include themify($themes) {
    border: 1px solid themed('colorText');
    color: themed('colorText');
    background: themed('colorBackground');
  }
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 20px 10px;
}

.react-autosuggest__suggestion--highlighted {
  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }
}