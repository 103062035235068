$themes: (
  light: (
    colorBackground: white,
    colorBackgroundBody: #f2f4f7,
    colorText: #646777,
    colorTextAdditional: #646777,
    logoImg: url(../shared/img/logo/logo.png),
    colorHover: #fafbfe,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
    colorBorderCustom: #BDC3C7,
    colorSvgIcon: #000,
    iconInvert: invert(100%),
    oddRowOrderLog: white,
    evenRowOrderLog: #fcfdfe,
    rowHoverOrderLog: #ecf0f1,
    colorDepthOrderSpan: #008a62,
    inputBackground: #eee,
    tableHeader: #f5f7f7,
    colorTextFont: #646777,
    loaderBorder: rgba(0, 0, 0, 0.2),
    colorBackgroundTooltip: rgba(247,247,247,0.85),
    colorTooltip: #333333,
    modalHeaderBackground: linear-gradient(to bottom, rgba(234, 238, 255, 0.6) 0, rgba(242, 244, 247, 0.65) 100%),
    modalHeaderColor: #646777,
    modalHeaderBorderColor: #BDC3C7,
    colorHtmlGray: #A9A9A9,
    tableBackgroundImage: linear-gradient(to bottom, #eaeeff 0%, #fff 48%, #eaeeff 100%),
  ),
  dark: (
    colorBackground: #2a2a31,
    colorBackgroundBody: #232329,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    logoImg: url(../shared/img/logo/logo.png),
    colorHover: #38373f,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorBorderCustom: #424242,
    colorSvgIcon: #fff,
    iconInvert: invert(0%),
    oddRowOrderLog: #2d3436,
    evenRowOrderLog: #262c2e,
    rowHoverOrderLog: #3d4749,
    colorDepthOrderSpan: #4ce1b6,
    inputBackground: #333333,
    tableHeader: #1c1f20,
    colorTextFont: #fff,
    loaderBorder: rgba(255, 255, 255, 0.2),
    colorBackgroundTooltip: rgba(0, 0, 0, 0.85),
    colorTooltip: #F0F0F0,
    modalHeaderBackground: linear-gradient(to bottom, rgba(92, 104, 156, 0.6) 0, rgba(68, 79, 97, 0.65) 100%),
    modalHeaderColor: white,
    modalHeaderBorderColor: #e8ebef,
    colorHtmlGray: #808080,
    tableBackgroundImage: linear-gradient(to bottom, #555 0%, #000 48%, #555 100%),
  )
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$color-accent: #4ce1b6;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;

$color-dark-blue: #2f84ff;
$color-dark-blue-hover: darken($color-dark-blue, 10%);
