.rc-tree-select-dropdown-light {
  background-color: white !important;
  color: #646777 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  > span {
    background: transparent !important;
    color: #646777 !important;
    border: 1px solid #646777 !important;
    border-radius: 0 !important;
    height: 85px !important;
    box-shadow: none !important;
  }
  .rc-tree-select-search input {
    border: none !important;
    padding: 0 !important;
    width: auto !important;
  }
  .rc-tree-select-search__field {
    background-color: white !important;
    color: #646777 !important;
  }
  .rc-tree-select-arrow{
    height: 16px;
  }
}

.rc-tree-select-dropdown-dark {
  background-color: #2a2a31 !important;
  color: #dddddd !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  > span {
    background: transparent !important;
    color: #dddddd !important;
    border: 1px solid #dddddd !important;
    border-radius: 0 !important;
    height: 85px !important;
    box-shadow: none !important;
  }
  .rc-tree-select-search input {
    border: none !important;
    padding: 0 !important;
    width: auto !important;
  }
  .rc-tree-select-search__field {
    background-color: #2a2a31 !important;
    color: #dddddd !important;
  }
  .rc-tree-select-arrow{
    height: 16px;
  }
}

.rc-tree-select-selection--multiple > ul > li {
  height: auto !important;
}

.rc-tree-select .rc-tree-select-selection__choice {
  background: transparent !important;
  border-radius: 2px !important;
  border: 1px solid #70bbfd !important;
}

.rc-tree-select-selection__choice__remove:hover {
  color: #70bbfd !important;
}

.rc-tree-select-enabled .rc-tree-select-selection:active {
  border-color: #4ce1b6 !important;
}

.rc-tree-select-enabled.rc-tree-select-focused .rc-tree-select-selection {
  border-color: #4ce1b6 !important;
  box-shadow: none !important;
}

.rc-tree-select {
  outline: none;
  .rc-tree-select-selection--single {
    height: 22px !important;
    cursor: auto;
  }
  .rc-tree-select-selection__rendered{
    line-height: 22px !important;
  }
}

.rc-tree-select-tree {
  li {
    span.rc-tree-select-tree-switcher {
      background: none !important;
    }
    span.rc-tree-select-tree-checkbox-disabled {
      background: none !important;
      position: relative;
    }
    span.rc-tree-select-tree-checkbox-disabled:before {
      content: "\f0c9";
      font-family: "Font Awesome 5 Free";
      position: absolute;
      top: -2px;
      color: #70bbfd;
      font-weight: 900;
    }
  }
  .rc-tree-select-tree-treenode-checkbox-checked .rc-tree-select-tree-node-content-wrapper, .rc-tree-select-tree-node-selected {
    background-color: #70bbfd;
    border: 1px #70bbfd solid;
    .rc-tree-select-tree-title {
      color: #000;
    }
  }
  .rc-tree-select-tree-node-content-wrapper {
    cursor: pointer;
  }
}

.rc-tree-select-selection--multiple {
  overflow: auto;
}

.rc-tree-select-selection__clear {
  cursor: pointer;
}

.rc-tree-select-dropdown {
  .rc-tree-select-tree {
    li {
      &.filter-node > .rc-tree-select-tree-node-content-wrapper {
        color: inherit !important;
        font-weight: inherit !important;
      }
    }
  }
}

.header-tree {
  z-index: 102 !important;
}