//
// Base styles
//
$switch-width: 40px;
$switch-height: 26px;
$switch-handle-margin: 2px;
$switch-handle-margin: 2px;
$switch-bg: #343b41;
$border-width: 1px;
$switch-handle-bg: #fff;
$border-color: #23282c;
$switch-label-width: 58px;
$switch-font-size: 10px;
$color: #f3f4f5;

.switch {
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
}

.switch-input {
  display: none;
}

.switch-slider {
  position: relative;
  display: block;
  height: inherit;
  cursor: pointer;
  background-color: $switch-bg;
  border: $border-width solid $border-color;
  transition: .15s ease-out;
  border-radius: .25rem;

  &::before {
    position: absolute;
    top: $switch-handle-margin;
    left: $switch-handle-margin;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: $switch-height - ($switch-handle-margin * 2) - ($border-width * 2);
    height: $switch-height - ($switch-handle-margin * 2) - ($border-width * 2);
    content: "";
    background-color: $switch-handle-bg;
    border: 1px solid $border-color;
    transition: .15s ease-out;
    border-radius: .125rem;
  }
}

.switch-input:checked ~ .switch-slider {
  &::before {
    transform: translateX($switch-width - $switch-height);
  }
}

.switch-input:disabled ~ .switch-slider {
  cursor: not-allowed;
  opacity: .5;
}

//
// Switch with label
//
.switch-label {
  width: $switch-label-width;

  .switch-slider {
    &::before {
      z-index: 2;
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 1px;
      z-index: 1;
      width: 50%;
      margin-top: -.5em;
      font-size: $switch-font-size;
      font-weight: 600;
      line-height: 1;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      content: attr(data-unchecked);
      transition: inherit;
    }
  }

  .switch-input:checked ~ .switch-slider {
    &::before {
      transform: translateX($switch-label-width - $switch-height);
    }
    &::after {
      left: 1px;
      color: #000;
      content: attr(data-checked);
    }
  }
}

//
// Switch Variants
//
.switch-light {
  .switch-input:checked + .switch-slider {
    background-color: $color;
    border-color: darken($color, 10%);

    &::before {
      border-color: darken($color, 10%);
    }
  }
}
