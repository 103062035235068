.depth {
  font-size: 10px;
  display: inline-block;
  min-width: 300px;
  .panel__body {
    border-radius: 0 !important;
    @include themify($themes) {
      border: 1px solid themed('colorBorderCustom') !important;
    }
  }
  .panel__btns {
    top: 0 !important;
  }
  .panel__title {
    text-transform: none;
    padding: 0 10px !important;
    @include themify($themes) {
      background: linear-gradient(to bottom, themed('colorBubbleActive') 0, themed('colorBubble') 100%);
    }
    @include themify($themes) {
      border-bottom: solid 1px themed('colorBorderCustom') !important;
    }
    margin-bottom: 0 !important;
  }
  .panel__content {
    padding: 0 !important;
  }

  .price-change-up::after, .price-change-down::after, .price-change-neutral::after, .price-change-before-up::before, .price-change-before-down::before, .price-change-before-neutral::before {
    content: '';
    display: inline-block;
    margin-left: 1px;
    width: 1px;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  .price-change-down::after, .price-change-before-down::before {
    border-top: 12px solid #c80000;
  }

  .price-change-up::after, .price-change-before-up::before {
    border-bottom: 12px solid #009c3a;
  }

  .price-change-neutral::after, .price-change-before-neutral::before {
    opacity: 0
  }
  .panel--collapse {
    height: 50px !important;
  }

  form {
    button {
      width: 100%;
    }
 /*   .Select--single > .Select-control .Select-value, .Select-placeholder {
      padding-left: 5px;
    }*/
  }

  .link {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.lm_not .depth {
  @media screen and (max-width: 767px) {
    min-width: 100%;
  }
}

.lm_depth .depth {
  min-width: 100%;
}

.depth-mw{
  .table{
    table-layout: fixed;
    border-collapse: separate;
  }
  .half-table .table-responsive {
    //width: 50%;
    display: inline-block;
  }
  .half-table .table {
    @include themify($themes) {
      border: 1px solid themed('colorBorderCustom');
    }
    background: linear-gradient(to bottom,#1e1e1f 0,#0a73ba 100%);
  }
  .half-table.greyed .table {
    @include themify($themes) {
      border: 1px solid themed('colorBorderCustom');
    }
    background: linear-gradient(to bottom,#1e1e1f 0,#403f48 100%);
  }
  .half-table .table-responsive:nth-child(1) .table{
    td:nth-child(1){
      width: 25%;
    }
    td:nth-child(2){
      width: 25%;
      border-right: 1px solid #424242;
    }
    td:nth-child(3){
      border-left: 1px solid #424242;
    }
  }
  .half-table .table-responsive:nth-child(2) .table{
    td:nth-child(1){
      width: 25%;
    }
    td:nth-child(2){
      width: 25%;
    }
  }
  .half-table .table-responsive .table td {
    width: 25%;
    text-overflow: ellipsis;
    overflow : hidden;
    white-space: nowrap;
  }
  .half-table .table-responsive .table:hover {
    td {
      text-overflow: clip;
      white-space: normal;
      word-break: break-all;
    }
  }
  .table tbody td, .table th {
    padding: 0 0 0 0 !important;
    // font-weight: bold;
    @include themify($themes) {
      color: themed('colorText') !important;
    }
  }
  .table-striped td {
    width: 100%;
  }
  .table td:nth-child(even), .table-striped td:last-child{
    text-align: right;
  }
  .table-striped td:nth-child(4) {
    width: 30px;
    text-align: center;
  }
  .half-table .table tbody td, .table th {
    color: white !important;
  }
  .depth-list td {
    text-align: center !important;
    text-overflow: ellipsis;
    overflow : hidden;
    white-space: nowrap;
  }
  table.depth-list:hover {
    td {
      text-overflow: clip;
      white-space: normal;
      word-break: break-all;
    }
  }
}

.depth-trading{
  max-width: 350px;
  padding-left: 0;
/*  .Select__control {
    min-height: 32px !important;
    max-height: 32px !important;
  }
  .Select__dropdown-indicator {
    padding: 5px;
  }
  .Select__input, .Select__input input {
    height: unset !important;
  }*/
  .form__form-group {
    margin-bottom: 2px;
  }
  .btn {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .form__form-group-label {
    margin-bottom: 0;
  }
}

.lm_not .depth-trading {
  @media screen and (max-width: 767px) {
    min-width: 100%;
    padding-right: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    min-width: 100%;
    padding-right: 0;
  }
  @media screen and (width: 1024px) {
    min-width: 100%;
    padding-right: 0;
  }
}

.lm_depth-trading .depth-trading {
  min-width: 100%;
  padding-right: 0;
}

.depth-order {
  min-width: calc(100% - 350px);
  padding-right: 0;
  padding-left: 0;

  .table{
    border-collapse: separate;
  }
  .table tr {
    font-size: 11px;
  }
  .table thead th{
    padding: 1px;
  }
  .table tbody td{
    padding: 1px;
    @include themify($themes) {
      color: themed('colorText');
    }
  }
  .table-responsive {
    max-height: 378px;
    //overflow: scroll;
  }
  .panel__btns {
    .panel__btn:nth-child(2) {
      display: none;
    }
  }
}

.lm_not .depth-order {
  @media screen and (max-width: 767px) {
    min-width: 100%;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    min-width: 100%;
  }

  @media screen and (width: 1024px) {
    min-width: 100%;
  }
}

.lm_depth-order .depth-order {
  min-width: 100%;
}

.trading__button, .trading__button__last {
  .form__form-group {
    margin-bottom: 0;
  }
}

.trading-dark {
  .popover-header {
    background: linear-gradient(to bottom, rgba(92, 104, 156, 0.6) 0, rgba(68, 79, 97, 0.65) 100%);
    color: white;
    border-bottom: solid 1px #e8ebef !important;
    background-color: #2a2a31 !important;
  }
  .popover-body {
    background-color: #2d3436;
    color: white;
    .btn {
      color: white;
    }
    input, textarea {
      color: white;
    }
  }
}

.trading-light {
  border: solid 1px #BDC3C7 !important;
  .popover-header {
    background: linear-gradient(to bottom, rgba(234, 238, 255, 0.6) 0, rgba(242, 244, 247, 0.65) 100%);
    color: #646777;
    border-bottom: solid 1px #BDC3C7 !important;
  }
  .popover-body {
    color: #646777;
    .btn {
      color: white;
    }
    input, textarea {
      color: #646777;
    }
  }
}
.sell__color {
  color: #ff4861 !important;
}
.buy__color {
  color: #2f84ff !important;
}

.notification--warning {
  .notification__message, .notification__title {
    color: #000 !important;
  }
}
