#grid-wrapper {
  .react-grid-Grid, .react-grid-Container, #grid-wrapper, .react-grid-Toolbar, .react-grid-Main, .react-grid-Header, .react-grid-HeaderCell, .react-grid-Canvas {
    @include themify($themes) {
      background-color: themed('oddRowOrderLog');
    }
    @include themify($themes) {
      border-color: themed('colorBorderCustom');
    }
    @include themify($themes) {
      color: themed('colorText');
    }
    input:focus {
      background: transparent;
      box-shadow: none;
    }
  }
  .react-grid-Main {
    outline: none;
  }
  .react-grid-Cell {
    outline: none;
    @include themify($themes) {
      border-color: themed('colorBorderCustom');
    }
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;

    .react-grid-Cell__value {
      white-space: normal;
      word-break: break-all;
    }
  }
  .react-grid-Row .react-grid-Cell {
    @include themify($themes) {
      background-color: themed('evenRowOrderLog');
    }
  }
  .react-grid-Cell:hover {
    @include themify($themes) {
      background-color: themed('rowHoverOrderLog');
    }
  }
  .react-grid-HeaderCell {
    @include themify($themes) {
      background-color: themed('tableHeader');
    }
  }
  .react-grid-Toolbar .btn {
    color: #fff;
    background-color: $color-blue;
    border-color: $color-blue;

    &:before {
      background-color: $color-blue-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-blue-hover;
    }
  }
  .rdg-selected {
    border: none;
  }
  .react-grid-Header {
    box-shadow: none;
  }
}

.rc-tree {
  font-size: 12px;
  li {
    .rc-tree-node-content-wrapper {
      height: auto !important;
    }
    span.rc-tree-switcher {
      background: none !important;
    }
    &.filter-node > .rc-tree-node-content-wrapper {
      @include themify($themes) {
        color: red !important;
      }
    }
  }
  .rc-tree-node-content-wrapper {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include themify($themes) {
      color: themed('colorText');
    }
  }
  .rc-tree-node-selected {
    background-color: #70bbfd;
    border: 1px #70bbfd solid;
    .rc-tree-node-content-wrapper {
      color: #000;
    }
  }
  .collapse {
    overflow: hidden;
    display: block;
  }

  .collapse-active {
    transition: height 0.3s ease-out;
  }
}

.btn-primary-follow-tail {
  &:before {
    background-color: initial !important;
  }
}

.depth-monitoring-log {
  min-width: calc(100% - 350px);
  padding-right: 0;
  padding-left: 0;

  .table {
    border-collapse: separate;
  }
  .table tr {
    font-size: 11px;
  }
  .table thead th {
    padding: 1px;
  }
  .table tbody td {
    padding: 1px;
    @include themify($themes) {
      color: themed('colorText');
    }
  }
  .table-responsive {
    overflow: scroll;
  }
  .panel__btns {
    .panel__btn:nth-child(2) {
      display: none;
    }
  }
}

.lm_not .depth-monitoring-log {
  @media screen and (max-width: 767px) {
    min-width: 100%;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    min-width: 100%;
  }

  @media screen and (width: 1024px) {
    min-width: 100%;
  }
}

.lm_depth-monitoring-log .depth-monitoring-log {
  min-width: 100%;
}

.monitoring-tree {
  display: inline-block;
  width: 20%;
  vertical-align: top;

  .rc-tree {
    height: 82vh;
    overflow: auto;
  }
}

.monitoring-tree-table {
  display: inline-block;
  width: 80%;
}

.lm_not .monitoring-tree, .lm_not .monitoring-tree-table {
  @media screen and (max-width: 1200px) {
    display: inline;
    width: 100%;
  }

  .rc-tree {
    @media screen and (max-width: 1200px) {
      height: 32vh;
    }
  }
}

.lm_monitoring-tree .monitoring-tree, .lm_monitoring-tree .monitoring-tree-table {
  display: inline;
  width: 100%;

  .rc-tree {
    height: 20vh;
  }
}

.ReactTable {
  // font-size: 10px;
  @include themify($themes) {
    color: themed('colorText');
  }

  .rt-thead .rt-th, .rt-thead .rt-td, .rt-tbody .rt-th, .rt-tbody .rt-td {
    padding: 1.1px 2px;
  }

  .rt-thead .rt-th, .rt-thead .rt-td, .rt-tbody .rt-tr-group, .rt-tbody .rt-td {
    @include themify($themes) {
      border-color: themed('colorBorderCustom');
    }
  }

  .pagination-bottom .-pagination .-btn {
    background: #70bbfd;
    color: #fff;
  }

  .pagination-bottom .-pagination .-btn:not([disabled]):hover {
    background: #3ea3fc;
  }

  .pagination-bottom .-pagination .-pageJump input, .rt-table .rt-thead.-filters input {
    background: transparent;
    @include themify($themes) {
      border-color: themed('colorText');
      color: themed('colorText');
    }
  }

  .rt-table .rt-thead .rt-th.-sort-desc, .rt-table .rt-thead .rt-td.-sort-desc {
    @include themify($themes) {
      box-shadow: inset 0 -3px 0 0 themed('colorSvgIcon');
    }
  }

  .rt-table .rt-thead .rt-th.-sort-asc, .rt-table .rt-thead .rt-td.-sort-asc {
    @include themify($themes) {
      box-shadow: inset 0 3px 0 0 themed('colorSvgIcon');
    }
  }

  .rt-expander:after {
    @include themify($themes) {
      border-top-color: themed('colorText');
    }
  }

  .rt-thead.-filters {
    .form__form-group-select {
      .Select__control {
        max-height: 62px;
        border-radius: 0;
        transition: all 0.3s;
        background: transparent;

        @include themify($themes) {
          border: 1px solid themed('colorText');
        }

        &.Select__control.Select__control--is-focused {
          border-color: $color-accent;
          box-shadow: none;
          background: transparent;
        }
      }

      .Select__placeholder, .Select__input {
        height: 30px;
      }

      .Select__input {
        width: 100%;
      }

      input {
        width: 100% !important;
        border: none;
        padding: 0;
      }

      .Select__value-container {
        margin-top: auto;

        .Select__single-value1 {
          background: transparent;
          border-color: $color-blue;
        }

        .Select__single-value, .Select__input {

          @include themify($themes) {
            color: themed('colorText') !important;
          }
        }

        .Select__value-icon {
          border-color: $color-blue;
        }

        .Select__multi-value__remove {
          color: hsl(0, 0%, 20%);
        }
      }

      .Select__multi-value-wrapper {

        .Select__input {
          width: inherit;
        }

        .Select__value {
          background: transparent;
          border-color: $color-blue;
        }

        .Select__value-label {

          @include themify($themes) {
            color: themed('colorText') !important;
          }
        }

        .Select__value-icon {
          border-color: $color-blue;
        }
      }

      .Select__menu {
        top: calc(100% + 1px);
        border-radius: 0;
        box-shadow: none;
        animation: open 0.3s ease-in-out;
        overflow: hidden;
        margin-top: 0;

        @include themify($themes) {
          border: 1px solid themed('colorText');
          background: themed('colorBackground');
        }
      }

      @keyframes open {
        0% {
          max-height: 0
        }
        100% {
          max-height: 200px
        }
      }

      .Select__group-heading {
        font-weight: bold;
        font-size: 11px;
        color: #70bbfd;
      }

      .Select__option {
        transition: all 0.3s;
        padding-left: 20px;
        cursor: pointer;

        @include themify($themes) {
          background: themed('colorBackground');
          color: themed('colorText');
        }

        &.Select__option--is-focused {
          @include themify($themes) {
            background: themed('colorHover');
          }
        }
      }

      .form__form-group-select-color {
        display: block;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        margin: auto 0 auto 5px;
      }
    }
  }
}

body {
  .Select__menu {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    animation: open 0.3s ease-in-out;
    overflow: hidden;
    margin-top: 0;

    .Select__option {
      transition: all 0.3s;
      cursor: pointer;
    }
  }

  .Select__menu.theme-light {
    border: 1px solid #646777;
    background: white;

    .Select__option {
      background: white;
      color: #646777;

      &.Select__option--is-focused {
        background: #fafbfe;
      }
    }
  }

  .Select__menu.theme-dark {
    border: 1px solid #dddddd;
    background: #2a2a31;

    .Select__option {
      background: #2a2a31;
      color: #dddddd;

      &.Select__option--is-focused {
        background: #38373f;
      }
    }
  }

  @keyframes open {
    0% {
      max-height: 0
    }
    100% {
      max-height: 200px
    }
  }
}

.ReactVirtualized__List {
  @include themify($themes) {
    background-color: themed('oddRowOrderLog');
    border: 1px solid themed('loaderBorder');
  }

  .ListItemEven,
  .ListItemOdd {
    display: flex;
    border-width: 1px 0 0;
    border-style: solid;
    padding: 0 12px;
    @include themify($themes) {
      color: themed('colorText');
      border-color: themed('loaderBorder');
    }
  }

  &:focus {
    outline: none;
  }
}

.monitoring-log-list {
  @include themify($themes) {
    background-color: themed('oddRowOrderLog');
    border: 1px solid themed('loaderBorder');
  }

  .ListItemEven,
  .ListItemOdd {
    white-space: nowrap;
    width: auto !important;
    @include themify($themes) {
      color: themed('colorText');
      border-top-style: solid;
      border-width: 1px;
      border-color: themed('loaderBorder');
    }
  }

  .ListItemOdd {
    @include themify($themes) {
      background-color: themed('evenRowOrderLog');
    }
  }
}