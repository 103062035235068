/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  width: 12px; /* 1px wider than Lion. */
  height: 10px;
  /* This is more usable for users trying to click it. */
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
  /* hover effect for both scrollbar area, and scrollbar 'thumb' */

  &:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: #808080;
  // background: rgba(0, 0, 0, 0.15);
  // -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px; /*Prevent it from getting too small */

  &:active {
    background: #999999; /* Some darker color when you click it */
    //  background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
    -webkit-border-radius: 100px;
  }
}

::-webkit-scrollbar-corner {
  background-color: rgba(0, 0, 0, 0.09);
}
