.ag-theme-balham, .ag-theme-balham-dark, .ag-theme-balham .ag-header, .ag-theme-balham-dark .ag-header{
  font: inherit !important;
  //font-size: 10px !important;
}
.ag-theme-balham .ag-header {
  color: #000 !important;
}

.ag-theme-balham-dark .ag-header {
  color: #fff !important;
}

.ag-status-bar {
  //display: none !important;
  overflow-x: auto !important;
}

.ag-theme-balham .ag-status-name-custom {
  color: var(--ag-header-foreground-color, var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54)));
}

.ag-theme-balham-dark .ag-status-name-custom {
  color: var(--ag-header-foreground-color, rgba(245, 245, 245, 0.64));
}

.ag-row .number {
  text-align: right;
}

.form__search .form__button-toolbar {
  float: left !important;
}

.rdtPicker {
  color: initial;
}

.rdt {
  input {
    background: transparent !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
}

.ag-row .price-change-up::after, .ag-row .price-change-down::after, .ag-row .price-change-neutral::after {
  content: '';
  display: inline-block;
  margin-left: 10px;
  width: 1px;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

.ag-row .price-change-down::after {
  border-top: 6px solid #e53935
}

.ag-row .price-change-up::after {
  border-bottom: 6px solid #43a047
}

.ag-row .price-change-neutral::after {
  opacity: 0
}

/* reduce ag-grid default header line-height and padding */
.ag-header-cell {
  line-height: normal !important;
  padding-top: 5px !important;
  padding-left: 8px !important;
  padding-right: 5px !important;
}

/* make header separator height proportional to header height */
.ag-header-cell:after,
.ag-header-group-cell:after {
  height: 50% !important;
}

/* set icons height to their real absolute value to ensure proper vertical alignment */
.ag-header-cell-menu-button .ag-icon-menu,
.ag-header-cell-label .ag-header-icon {
  height: 16px !important;
}

/* make header text wrap, without breaking words and without ellipsis */
.ag-header-cell-label .ag-header-cell-text {
  height: auto !important;
  overflow: visible !important;
  overflow-wrap: normal !important;
  text-overflow: clip !important;
  white-space: normal !important;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.cell-wrap-text {
  white-space: normal !important;
}

.ag-multi-list-custom {
  width: 200px;
}

.ag-checkbox-btn {
  width: 100px;
  padding-top: 0;
  .checkbox-btn__checkbox-custom{
    height: 14px !important;
    width: 14px;
  }
  .checkbox-btn__checkbox-custom svg{
    height: 12px;
    width: 12px;
  }
}

.ag-header-cell-label .ag-checkbox-btn {
  width: 15px;
}

@-moz-document url-prefix() {
  .card {
    .ag-theme-balham, .ag-theme-balham-dark {
      word-wrap: normal;
    }
  }
}

.outer-grid-label {
  display: inline-block;
  margin-top: 7px;
  @include themify($themes) {
    color: themed('colorText');
  }
}

.outer-grid-button {
  display: inline-block;
  float: right;
}

.arrow-right, .arrow-right-chk .ag-cell-value, .arrow-right-expand .ag-group-value {
  padding-left: 2px !important;
}

.arrow-right::before, .arrow-right-chk .ag-cell-value::before, .arrow-right-expand .ag-group-value::before {
  content: '';
  display: inline-block;
  margin-right: 1px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 8px solid $color-blue-hover;
}

.edit-row-color {
  background-color: $color-blue-hover;
  color: #000;
}

.ag-popup-editor{
  .form__form-group-select {
    .Select__control {
      border-radius: 0;
      transition: all 0.3s;
      background: transparent;

      @include themify($themes) {
        border: 1px solid themed('colorText');
      }
      &.Select__control.Select__control--is-focused {
        border-color: $color-accent;
        box-shadow: none;
        background: transparent;
      }
    }

    .Select__placeholder, .Select__input {
      height: 30px;
    }

    .Select__input {
      width: 100%;
    }

    input {
      width: 100% !important;
      border: none;
      padding: 0;
    }
    .Select__value-container {
      margin-top: auto;
      max-height: 150px;
      overflow: auto;
      .Select__single-value1 {
        background: transparent;
        border-color: $color-blue;
      }

      .Select__single-value, .Select__input {

        @include themify($themes) {
          color: themed('colorText') !important;
        }
      }

      .Select__value-icon {
        border-color: $color-blue;
      }
      .Select__multi-value__remove {
        color: hsl(0, 0%, 20%);
      }
    }
    .Select__multi-value-wrapper {

      .Select__input {
        width: inherit;
      }

      .Select__value {
        background: transparent;
        border-color: $color-blue;
      }

      .Select__value-label {

        @include themify($themes) {
          color: themed('colorText') !important;
        }
      }

      .Select__value-icon {
        border-color: $color-blue;
      }
    }

    .Select__menu {
      top: calc(100% + 1px);
      border-radius: 0;
      box-shadow: none;
      animation: open 0.3s ease-in-out;
      overflow: auto;
      margin-top: 0;

      @include themify($themes) {
        border: 1px solid themed('colorText');
        background: themed('colorBackground');
      }
    }

    @keyframes open {
      0% {
        max-height: 0
      }
      100% {
        max-height: 1px
      }
    }

    .Select__group-heading{
      font-weight: bold;
      font-size: 11px;
      color: #70bbfd;
    }

    .Select__option {
      transition: all 0.3s;
      padding-left: 20px;
      cursor:pointer;

      @include themify($themes) {
        background: themed('colorBackground');
        color: themed('colorText');
      }

      &.Select__option--is-focused {
        @include themify($themes) {
          background: themed('colorHover');
        }
      }
    }

    .form__form-group-select-color {
      display: block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: auto 0 auto 5px;
    }
  }

  textarea {
    background: transparent;
    @include themify($themes) {
      color: themed('colorText');
    }
  }
}

.ag-theme-balham .ag-cell, .ag-theme-balham-dark .ag-cell {
  padding-left: 5px !important;
  padding-right: 5px !important;
  line-height: 18px !important;
}

.ag-header-icon {
  position: absolute;
  margin-left: -11px !important;
}

.ag-theme-balham .ag-cell, .ag-theme-balham .ag-header-cell {
  border-right: 1px solid #BDC3C7 !important;
}

.ag-theme-balham-dark .ag-cell, .ag-theme-balham-dark .ag-header-cell {
  border-right: 1px solid #424242 !important;
}

.wordwrap {
  white-space: pre-line !important;
  word-wrap: break-word;
}

.ag-theme-balham .ag-body .ag-icon-expanded, .ag-theme-balham-dark .ag-body .ag-icon-expanded,
.ag-theme-balham .ag-body .ag-group-expanded .ag-icon-contracted:empty,
.ag-theme-balham-dark .ag-body .ag-group-expanded .ag-icon-contracted:empty {
  height: 13px;
}

span.cell-renderer-red {
  background: #ff0066;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  line-height: 1.6em;
  padding-right: 2px;
  padding-left: 2px;
}

span.cell-renderer-blue {
  background: #6600ff;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  line-height: 1.6em;
  padding-right: 2px;
  padding-left: 2px;
}

span.cell-renderer-brown {
  background: #772120;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  line-height: 1.6em;
  padding-right: 2px;
  padding-left: 2px;
}

span.cell-renderer-green {
  background: #008000;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  line-height: 1.6em;
  padding-right: 2px;
  padding-left: 2px;
}

span.cell-renderer-orange {
  background: #FFA500;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  line-height: 1.6em;
  padding-right: 2px;
  padding-left: 2px;
}

.customHeaderMenuButton {
  float: left;
  margin: 0 0 0 3px;
}

.customHeaderLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortDownLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortUpLabel {
  float: left;
  margin: 0;
}

.customSortRemoveLabel {
  float: left;
  margin: 0 0 0 3px;
  font-size: 11px;
}

.active {
  color: cornflowerblue;
}

.popover {
  .Select__value-container--is-multi {
    margin-top: auto;
    max-height: 60px;
    overflow: auto;
  }
}

.ag-theme-balham, .ag-theme-balham-dark {
  .ag-header-cell {
    .ag-header-cell-label-custom {
      .ag-checkbox-btn {
        margin-bottom: 12px;
      }
    }
  }
}

.btn.two-icon-button {
  padding: 1px 15px;
}

p.two-icon-button {
  display: inline-block;
}

p.two-icon-button-second {
  margin: 0 0 0 0;
}

.bar-limit-chart {
  .highchart-custom-tooltip {
    display: block;
    padding: 9px;
    margin-left: 1px;
    margin-top: 1px;
    @include themify($themes) {
      background-color: themed('colorBackgroundTooltip');
      color: themed('colorTooltip');
    }
  }

  .highcharts-table {
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    tbody td, th {
      border: none;
      padding: 10px;

      @include themify($themes) {
        color: themed('colorText');
      }

      &.width-25 {
        width: 25%;
      }

      &.width-100 {
        width: 100%;
      }

      &.align-right {
        text-align: right;
      }

      &.align-center {
        text-align: center;
      }
    }

    thead th {

      @include themify($themes) {
        color: themed('colorText');
        border-bottom-color: themed('colorBorder');
      }
    }
  }

  .highcharts-xaxis-labels span:nth-child(odd) {
    @include themify($themes) {
      background: themed('colorBorderCustom');
    }
  }

  .highcharts-xaxis-labels {
    width: 30%;
  }

  .highcharts-xaxis-labels span {
    white-space: normal !important;
    width: 100% !important;
  }
}

.session-modal {
  max-width: 720px;
}

.session-table {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

.session-table td, .session-table th {
  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorderCustom');
  }
  padding: 4px;
}

.session-table tr:nth-child(even) {
  @include themify($themes) {
    background-color: themed('colorHover');
  }
}

.session-popover {
  max-width: 720px;

  .popover{
    min-width: 720px;
  }
}

.float-right {
  float: right;
}

body .lm_content {
  overflow: scroll;
}

.lm_goldenlayout:empty:before {
  content: "Drag the menus from the left side menu panel and drop it here";
  @include themify($themes) {
    color: themed('colorText');
  }
  font-size: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 350px 0;
  width: 40%;
}

.multi_window {
  .dropdown {
    top: 15px;

    &.main .btn-group > .btn:first-child {
      margin-right: 0;
      max-width: 150px;
      padding: 4px 2px;
      cursor: default;
      @media screen and (max-width: 576px) {
        display: none;
      }

      &:before, &:hover, &:focus, &:active, &:active:focus {
        background-color: transparent;
        @include themify($themes) {
          color: themed('colorText');
        }
      }
    }

    .btn-group > .btn:last-child {
      padding: 4px 2px;
    }

    &.sub .btn-group > .btn:last-child {
      background-color: $color-dark-blue;
      border-color: $color-dark-blue;

      &:before, &:hover, &:focus, &:active, &:active:focus {
        background-color: $color-dark-blue-hover;
      }

      @media screen and (max-width: 576px) {
        display: none;
      }
    }

    .btn {
      border-radius: 0;
      max-height: 30px;
    }
  }
}

.balance_transaction_form {
  width: 75%;
  display: inline-block;
}

.balance_transaction_state {
  width: 25%;
  display: inline-block;
}

.lm_not .balance_transaction_form, .lm_not .balance_transaction_state {
  @media screen and (max-width: 1200px) {
    width: 100%;
    display: inline;
  }
}

.lm_balance_transaction_form .balance_transaction_form, .lm_balance_transaction_form .balance_transaction_state {
  width: 100%;
  display: inline;
}

.show {
  display: block;
}

.hide {
  display: none;
}

#root {
  .ag-cell-inline-editing {
    border: none !important;
  }

  .ag-root, .ag-popup-editor {
    input, textarea {
      &:focus, &:active {
        outline: none !important;
        border-color: $color-accent !important;
        box-shadow: none !important;
      }
    }
  }
}

.borderValuesForm {
  padding: 10px;
  @include themify($themes) {
    border: 1px solid themed('colorHtmlGray');
  }
  margin-bottom: 10px;

  .form__form-group-input-wrap {
    padding-right: 5px;
  }

  .form__form-group-field {
    padding-left: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
  }
}

.marginLeftAuto {
  margin-left: auto;
}

.btnIcon {
  border: 1px solid transparent;
  display: inline-flex;
  &:hover, &:focus, &:active, &:active:focus {
    border: 1px solid #70bbfd;
  }
}

.collapse-left .collapse__title {
  padding: 5px 5px !important;
  text-transform: uppercase;
}

div.topbar-table-div {
  margin-top: 7px;
  height: 36px;
}

table.topbar-table {
  @include themify($themes) {
    background-image: themed('tableBackgroundImage');
  }

  td {
    padding: 0;
    border-top: none;
    @include themify($themes) {
      color: themed('colorText');
    }
    text-align: center;
  }

  td:not(:last-child) {
    border-right: 1px solid #333246;
  }
}

.hide-check-header-group .ag-checkbox-input-wrapper {
  display: none !important;
}

.MuiTable-root .MuiTableCell-root {
  padding: 0;
  font-size: inherit;
}

.broker-risk-select {
  @media screen and (max-width: 876px) {
    width: 90px;
  }
  @media screen and (max-width: 425px) {
    width: 30px;
  }
}

.commands-container {
  .commands-form {
    .form__form-group-input-wrap {
      max-width: 300px;
    }

    .large-form__form-group-input-wrap {
      max-width: 200px;
    }

    .small-form__form-group-input-wrap {
      max-width: 100px;
    }

    .command-button {
      min-width: 136px;
    }

  }

  .card-text {
    height: calc(100vh - 100px);
    overflow-y: auto;
  }
}