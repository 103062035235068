// @import '~react-select/dist/react-select.min.css';
@import '~react-datetime/css/react-datetime.css';

.form {
  display: flex;
  flex-wrap: wrap;

  input, textarea, .input-style {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    // height: 32px;
    height: 22px;
    transition: border 0.3s;
    background: transparent;

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    @include themify($themes) {
      // border: 1px solid themed('colorFieldsBorder');
      border: 1px solid themed('colorText');
      color: themed('colorText');
    }

    &[disabled] {

      @include themify($themes) {
        background: themed('colorBackgroundBody');
      }

      &:focus, &:active {

        @include themify($themes) {
          // border-color: themed('colorBorder');
          border-color: themed('colorText');
        }
      }
    }

    &:focus, &:active {
      outline: none;
      border-color: $color-accent;
    }
  }

  textarea {
    min-height: 85px;
  }

  &.form--horizontal {

    .form__form-group {
      display: flex;
      flex-wrap: wrap;
    }

    .form__form-group-field {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 80px;
    }

    .form__form-group-label {
      width: 80px;
      max-height: 32px;
      line-height: 18px;
      margin: auto 0;

      & + .form__form-group-field {
        margin-left: 0;
      }
    }

    .form__form-group-description {
      margin-left: 90px;
    }

    .form__button-toolbar {
      margin-left: 0;
    }

    .form__form-group-input-wrap--error-above {
      margin-bottom: 15px;

      .form__form-group-error {
        top: -28px;
      }
    }
  }

  .lm_not &.form--horizontal {
    @media screen and (min-width: 480px) {

      .form__form-group-label {
        width: 120px;
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        padding-left: 20px;
      }

      .form__form-group-description, .form__button-toolbar {
        margin-left: 140px;
      }
    }
  }

  .lm_form--horizontal &.form--horizontal {
    .form__form-group-label {
      width: 120px;
    }

    .form__form-group-field {
      width: calc(100% - 120px);
      padding-left: 20px;
    }

    .form__form-group-description, .form__button-toolbar {
      margin-left: 140px;
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      margin-right: 50px;
    }

    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    .form__select-color {
      display: flex;
      flex-wrap: wrap;
      max-width: 84px;
      margin-right: 40px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }

  .form__form-group-select {
    // width: 100%;
    // height: 32px;
    // font-size: 12px;

    .Select-control {
      height: 32px;
      border-radius: 0;
      transition: all 0.3s;
      background: transparent;

      @include themify($themes) {
        // border: 1px solid themed('colorFieldsBorder');
        border: 1px solid themed('colorText');
      }
    }

    .Select-placeholder, .Select-input {
      height: 30px;
    }

    .Select-input {
      width: 100%;
    }

    input {
      width: 100% !important;
      border: none;
      padding: 0;
    }

    .Select-multi-value-wrapper {

      .Select-input {
        width: inherit;
      }

      .Select-value {
        background: transparent;
        border-color: $color-blue;
      }

      .Select-value-label {

        @include themify($themes) {
          color: themed('colorText') !important;
        }
      }

      .Select-value-icon {
        border-color: $color-blue;
      }
    }

    .Select-menu-outer {
      top: calc(100% + 1px);
      border-radius: 0;
      box-shadow: none;
      font-size: 12px;
      animation: open 0.3s ease-in-out;
      overflow: hidden;

      @include themify($themes) {
        // border: 1px solid themed('colorBorder');
        border: 1px solid themed('colorText');
        background: themed('colorBackground');
      }
    }

    @keyframes open {
      0% {
        max-height: 0
      }
      100% {
        max-height: 200px
      }
    }

    .Select-option {
      transition: all 0.3s;
      border-radius: 0;
      display: flex;

      @include themify($themes) {
        background: themed('colorBackground');
        color: themed('colorText');
      }

      &.is-focused {
        @include themify($themes) {
          background: themed('colorHover');
        }
      }
    }

    &.is-focused, &.is-focused:not(.is-open) {

      .Select-control {
        border-color: $color-accent;
        box-shadow: none;
        background: transparent;
      }
    }

    .form__form-group-select-color {
      display: block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: auto 0 auto 5px;
    }
  }
  .form__form-group-select {
    .Select__control {
      max-height: 62px;
      border-radius: 0;
      transition: all 0.3s;
      background: transparent;

      @include themify($themes) {
        border: 1px solid themed('colorText');
      }
      &.Select__control.Select__control--is-focused {
        border-color: $color-accent;
        box-shadow: none;
        background: transparent;
      }
    }

    .Select__placeholder, .Select__input {
      height: 30px;
    }

    .Select__input {
      width: 100%;
    }

    input {
      width: 100% !important;
      border: none;
      padding: 0;
    }
    .Select__value-container {
      margin-top: auto;
      .Select__single-value {
        max-width: calc(100% - 50px);
      }

      .Select__single-value, .Select__input {

        @include themify($themes) {
          color: themed('colorText') !important;
        }
      }

      .Select__value-icon {
        border-color: $color-blue;
      }
      .Select__multi-value__remove {
        color: hsl(0, 0%, 20%);
      }
    }
    .Select__multi-value-wrapper {

      .Select__input {
        width: inherit;
      }

      .Select__value {
        background: transparent;
        border-color: $color-blue;
      }

      .Select__value-label {

        @include themify($themes) {
          color: themed('colorText') !important;
        }
      }

      .Select__value-icon {
        border-color: $color-blue;
      }
    }

    .Select__menu {
      top: calc(100% + 1px);
      border-radius: 0;
      box-shadow: none;
      animation: open 0.3s ease-in-out;
      overflow: hidden;
      margin-top: 0;

      @include themify($themes) {
        border: 1px solid themed('colorText');
        background: themed('colorBackground');
      }
    }

    @keyframes open {
      0% {
        max-height: 0
      }
      100% {
        max-height: 200px
      }
    }

    .Select__group-heading{
      font-weight: bold;
      font-size: 11px;
      color: #70bbfd;
    }

    .Select__option {
      transition: all 0.3s;
      padding-left: 20px;
      cursor:pointer;

      @include themify($themes) {
        background: themed('colorBackground');
        color: themed('colorText');
      }

      &.Select__option--is-focused {
        @include themify($themes) {
          background: themed('colorHover');
        }
      }
    }

    .form__form-group-select-color {
      display: block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: auto 0 auto 5px;
    }
  }
}

.form__half {
  width: calc(50% - 15px);
  height: 100%;

  &:first-child, &.trading__button {
    margin-right: 30px;
  }

  .form__button-toolbar {
    float: right;
  }
}

.lm_not .form__half {
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-right: 0;
    &:first-child, &.trading__button {
      margin-right: 0;
    }
  }
}

.lm_not .form__quarter, .lm_not .form__fifth, .lm_not .form__triad {
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-right: 0;
    &:not(:last-child), &.trading__button {
      margin-right: 0;
    }
  }
}

.lm_form__half .form__half {
  width: 100%;
  margin-right: 0;

  &:first-child, &.trading__button {
    margin-right: 0;
  }
}

.lm_form__half .form__quarter, .lm_form__half .form__fifth, .lm_form__half .form__triad {
  width: 100%;
  margin-right: 0;

  &:not(:last-child), &.trading__button {
    margin-right: 0;
  }
}

.form__quarter {
  width: calc(25% - 40px);
  height: 100%;

  &:not(:last-child), &.trading__button {
    margin-right: 40px;
  }

  .form__button-toolbar {
    float: right;
  }
}

.form__fifth {
  width: calc(20% - 40px);
  height: 100%;

  &:not(:last-child), &.trading__button {
    margin-right: 40px;
  }

  .form__button-toolbar {
    float: right;
  }
}

.form__triad {
  width: calc(33.33% - 40px);
  height: 100%;

  &:not(:last-child), &.trading__button {
    margin-right: 40px;
  }

  .form__button-toolbar {
    float: right;
  }
}

.form__form-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.form__form-group--address {

  input:last-child {
    margin-left: 15px;
    width: 70%;
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;

  @include themify($themes) {
    color: themed('colorText');
  }

  span {
    color: #dddddd;
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;

  @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: #ffffff;
    }
  }
}

.form__form-group-file {

  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &:hover {

      @include themify($themes) {
        background: themed('colorFieldsBorder');
      }
    }
  }

  span {
    padding-left: 10px;
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  padding: 2px;
  height: 22px;

  @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__form-group-description {
  font-size: 10px;
  color: $color-additional;
  line-height: 13px;
  margin-top: 2px;
}

.form__button-toolbar {
  margin-top: 10px;
}

.form__form-group-input-wrap {
  width: 100%;
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #f18383;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-input-wrap--error-above {

  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    padding: 5px 10px;
    background: #ffbcbc;
    color: #ad4444;
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;

  .form__form-group-date {
    width: 100%;
    margin-right: 20px;
  }

  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }
}

.lm_not .form__form-group-date-cvc {
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-date {
      margin-right: 0;
    }

    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.lm_form__form-group-date-cvc .form__form-group-date-cvc {
  flex-wrap: wrap;

  .form__form-group-date {
    margin-right: 0;
  }

  .form__form-group-cvc {
    max-width: 100%;
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;

  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px;
  }
}

.lm_not .form__form-group-id-category {
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-id {
      margin-right: 0;
      width: 100%;
    }
  }
}

.lm_form__form-group-id-category .form__form-group-id-category {
  flex-wrap: wrap;

  .form__form-group-id {
    margin-right: 0;
    width: 100%;
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }

  .form__form-group-price {
    margin-right: 20px;
  }
}

.lm_not .form__form-group-price-discount {
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-price {
      margin-right: 0;
    }
  }
}

.lm_form__form-group-price-discount .form__form-group-price-discount {
  flex-wrap: wrap;

  .form__form-group-price {
    margin-right: 0;
  }
}

.multiple-checkboxes {
  flex-wrap: wrap;
  label {
    margin-right: 10px;
  }
}

.validity-dd {
  .Select__dropdown-indicator, .Select__value-container {
    padding: 0;
  }
  .Select__single-value {
    overflow: visible;
  }
}

.fieldset {
  border: 2px groove threedface;
  border-top: none;
  padding: 0.5em;
  margin: 1em 2px;
  width: 100%;
}

.fieldset > h1 {
  font: 1em normal;
  margin: -1em -0.5em 0;
}

.fieldset > h1 > span {
  float: left;
}

.fieldset > h1:before {
  border-top: 2px groove threedface;
  content: ' ';
  float: left;
  margin: 0.5em 2px 0 -1px;
  width: 0.75em;
}

.fieldset > h1:after {
  border-top: 2px groove threedface;
  content: ' ';
  display: block;
  height: 1.5em;
  left: 2px;
  margin: 0 1px 0 0;
  overflow: hidden;
  position: relative;
  top: 0.5em;
}

.product-card__wish-btn {
  display: block;
  padding: 10px 0;
  font-size: 14px;
  color: $color-blue;
  height: 42px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s;

  svg {
    height: 14px;
    fill: $color-blue;
    transition: all 0.3s;
  }

  &:hover {
    color: $color-blue-hover;

    svg {
      fill: $color-blue-hover;
    }
  }
}

.risk_account_modal_body {
  max-height: 500px;
  overflow: scroll;
}

.Select--is-disabled {
  opacity: 0.2;
}

.form__button-toolbar-remove-top-padding {
  margin-top: -10px !important;
}

.input-css {
  width: 100%;
  padding: 5px 10px;
  font-size: 12px;
  // height: 32px;
  height: 22px;
  transition: border 0.3s;
  background: transparent;

  &::-webkit-input-placeholder {
    color: $color-additional;
  }
  &::-moz-placeholder {
    color: $color-additional;
  }
  /* Firefox 19+ */
  &:-moz-placeholder {
    color: $color-additional;
  }
  /* Firefox 18- */
  &:-ms-input-placeholder {
    color: $color-additional;
  }

  @include themify($themes) {
    // border: 1px solid themed('colorFieldsBorder');
    border: 1px solid themed('colorText');
    color: themed('colorText');
  }

  &[disabled] {

    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }

    &:focus, &:active {

      @include themify($themes) {
        // border-color: themed('colorBorder');
        border-color: themed('colorText');
      }
    }
  }

  &:focus, &:active {
    outline: none;
    border-color: $color-accent;
  }
}

.label-text {
  @include themify($themes) {
    color: themed('colorText');
  }
}

.form-small-item{
  width: 21%;
}

.react-autosuggest__container .react-autosuggest__suggestions-container--open {
  max-height: 200px;
  overflow: auto;
}

.taker_api_link_form .react-autosuggest__container .react-autosuggest__suggestions-container--open {
  width: calc(100% - 140px);
}

.react-autosuggest__container .react-autosuggest__suggestion {
  padding: 10px 10px;
}
